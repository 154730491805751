import './domainCheck'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueMeta from 'vue-meta'
import { DateTime } from 'luxon'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';

require('./firebase')
 
Vue.use(VueMeta)
Vue.use(VueSplide)

Vue.config.productionTip = false

Vue.prototype.img = (path: string) => {
  return navigator.webdriver ? `https://${process.env.VUE_APP_HOST}${path}` : path
}

Vue.filter('date', (date: DateTime | Date) => {
  if (date instanceof DateTime) {
    return date.setLocale(i18n.locale).toLocaleString(DateTime.DATE_FULL)
  }
  if (date instanceof Date) {
    return DateTime.fromJSDate(date).setLocale(i18n.locale).toLocaleString(DateTime.DATE_FULL)
  }
  return date
})

Vue.filter('time', (date: unknown) => {
  if (date instanceof DateTime) {
    return date.setLocale(i18n.locale).toLocaleString(DateTime.TIME_24_SIMPLE)
  }
  if (date instanceof Date) {
    return DateTime.fromJSDate(date).setLocale(i18n.locale).toLocaleString(DateTime.TIME_24_SIMPLE)
  }
  return date
})

Vue.filter('relativeTime', (date: unknown) => {
  let dateTime;
  if (date instanceof DateTime) {
    dateTime = date.setLocale(i18n.locale)
  }
  else if (date instanceof Date) {
    dateTime = DateTime.fromJSDate(date).setLocale(i18n.locale)
  } else {
    return date
  }
  return dateTime.toRelative({ unit: ['days', 'hours', 'minutes'] })
})

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  }
})