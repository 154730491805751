import api from "@/api";
import { auth } from "@/firebase";
import i18n from "@/i18n";
import { Booking } from "@/types/booking";
import { DateTime } from "luxon";
import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reducer: (state: any) => ({ pins: state.pins }),
});

const store = new Vuex.Store({
  state: {
    bookings: [],
    pins: {} as Record<string, string>, // { iJKkklCtXHzRIRKY3GSP: "406884" }
  },
  getters: {
    services() {
      return [
        { header: i18n.t("Services.Services.WheelChange.Title") },
        { key: i18n.t("Services.Services.WheelChange.Title") + ' (x1)', name: i18n.t("Services.Services.WheelChange.Title"), count: 1 },
        { key: i18n.t("Services.Services.WheelChange.Title") + ' (x2)', name: i18n.t("Services.Services.WheelChange.Title"), count: 2 },
        { key: i18n.t("Services.Services.WheelChange.Title") + ' (x3)', name: i18n.t("Services.Services.WheelChange.Title"), count: 3 },
        { key: i18n.t("Services.Services.WheelChange.Title") + ' (x4)', name: i18n.t("Services.Services.WheelChange.Title"), count: 4 },
  
        { header: i18n.t("Services.Services.Balancing.Title") },
        { key: i18n.t("Services.Services.Balancing.Title") + ' (x1)', name: i18n.t("Services.Services.Balancing.Title"), count: 1 },
        { key: i18n.t("Services.Services.Balancing.Title") + ' (x2)', name: i18n.t("Services.Services.Balancing.Title"), count: 2 },
        { key: i18n.t("Services.Services.Balancing.Title") + ' (x3)', name: i18n.t("Services.Services.Balancing.Title"), count: 3 },
        { key: i18n.t("Services.Services.Balancing.Title") + ' (x4)', name: i18n.t("Services.Services.Balancing.Title"), count: 4 },
  
        { header: i18n.t("Services.Services.Mounting.Title") },
        { key: i18n.t("Services.Services.Mounting.Title") + ' (x1)', name: i18n.t("Services.Services.Mounting.Title"), count: 1 },
        { key: i18n.t("Services.Services.Mounting.Title") + ' (x2)', name: i18n.t("Services.Services.Mounting.Title"), count: 2 },
        { key: i18n.t("Services.Services.Mounting.Title") + ' (x3)', name: i18n.t("Services.Services.Mounting.Title"), count: 3 },
        { key: i18n.t("Services.Services.Mounting.Title") + ' (x4)', name: i18n.t("Services.Services.Mounting.Title"), count: 4 },
  
        { header: i18n.t("Services.Services.Storage.Title") },
        { key: i18n.t("Services.Services.Storage.Title") + ' (x4)', name: i18n.t("Services.Services.Storage.Title"), count: 4 },
  
        { header: i18n.t("Services.Services.Other") },
        { key: i18n.t("Services.Services.Other"), name: i18n.t("Services.Services.OtherDescription") },
      ];
    }
  },
  mutations: {
    setBookings(state, bookings) {
      state.bookings = bookings;
    },
    rememberPin(state, { id, pin }) {
      state.pins[id] = pin;
    },
    forgetPin(state, id) {
      Vue.delete(state.pins, id);
    },
    forgetAllPins(state) {
      state.pins = {};
    },
  },
  actions: {
    async fetchBookings({ commit }) {
      const results = await api.getBookings();
      commit("setBookings", results);
      return results;
    },
    async createBooking(store, booking: Booking) {
      const result = await api.createBooking(booking);
      return result;
    },
    async fetchOneBooking(store, { id, pin }: { id: string; pin: string }) {
      const result = await api.getOneBooking(id, pin);
      return result;
    },
  },
  modules: {
    admin: {
      namespaced: true,
      state: {
        bookings: {},
        unavailabilities: [],
      },
      mutations: {
        setBookings(state, bookings) {
          for (const b of bookings) {
            state.bookings[b.id] = b
          }
        },
        setUnavailabilities(state, unavailabilities) {
          state.unavailabilities = unavailabilities
        }
      },
      actions: {
        async fetchBookings({ commit }, { from, to }: { from?: DateTime, to?: DateTime }) {
          const results = await api.adminGetBookings(from, to);
          commit("setBookings", results);
          return results;
        },
        async updateBooking(store, { id, booking}) {
          return await api.adminUpdateBookings(id, booking)
        },
        async getUnavailabilities({ commit }) {
          const result = await api.getUnavailabilities()
          commit("setUnavailabilities", result)
          return result
        },
        async deleteUnavailability(store, id) {
          await api.deleteUnavailability(id)
        }
      }
    },
    auth: {
      namespaced: true,
      state: {
        user: null
      },
      getters: {
        loggedIn(state) {
          return !!state.user
        },
      },
      mutations: {
        setUser(state, user) {
          state.user = user
        }
      },
      actions: {
        async signInWithEmailAndPassword({ commit, dispatch }, { email, password }: { email: string, password: string }) {
          const result = await auth.signInWithEmailAndPassword(auth.getAuth(), email, password)
          try {
            await api.adminGetBookings(DateTime.now(), DateTime.now())
          } catch (error) {
            dispatch('signOut')
          }
          const user = result.user;
          commit('setUser', user)
        },
        async signInWithPopup({ commit, dispatch }) {
          const result = await auth.signInWithPopup(auth.getAuth(), new auth.GoogleAuthProvider())
          try {
            await api.adminGetBookings(DateTime.now(), DateTime.now())
          } catch (error) {
            dispatch('signOut')
          }
          const user = result.user;
          commit('setUser', user)
        },
        async signOut() {
          await auth.signOut(auth.getAuth())
        }
      }
    }
  },
  plugins: [vuexLocal.plugin],
});

auth.getAuth().onAuthStateChanged((user) => {
  store.commit('auth/setUser', user)
})

export default store;