
import { Vue, Component, Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import SavedBookingManagerDialog from "@/components/SavedBookingManagerDialog.vue";

@Component({
  components: {
    SavedBookingManagerDialog,
  },
  metaInfo() {
    return {
      title: "Fontos",
      titleTemplate: "%s - Fontos Mobiler Reifenservice",
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      link: [
        { rel: "canonical", href: `https://${process.env.VUE_APP_HOST}/${this.$i18n.locale}/` },
        {
          rel: "alternate",
          href: `https://${process.env.VUE_APP_HOST}/de/`,
          hreflang: "de",
        },
        {
          rel: "alternate",
          href: `https://${process.env.VUE_APP_HOST}/en/`,
          hreflang: "en",
        },
        {
          rel: "alternate",
          href: `https://${process.env.VUE_APP_HOST}/hu/`,
          hreflang: "hu",
        },
      ],
      meta: [
        { name: "title", content: "Fontos Mobiler Reifenservice" },
        { name: "description", content: this.$t("Meta.Description") as string },
        // Facebook
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: `https://${process.env.VUE_APP_HOST}/${this.$i18n.locale}`,
        },
        { property: "og:title", content: "Fontos Mobiler Reifenservice" },
        {
          property: "og:description",
          content: this.$t("Meta.Description") as string,
        },
        {
          property: "og:image",
          content: `https://${process.env.VUE_APP_HOST}/img/fontos-reifen-preview.jpg`,
        },
        // Twitter
        { property: "twitter:card", content: "summary_large_image" },
        { property: "twitter:title", content: "Fontos Mobiler Reifenservice" },
        { property: "twitter:title", content: "Fontos Mobiler Reifenservice" },
        {
          property: "twitter:description",
          content: this.$t("Meta.Description") as string,
        },
        {
          property: "twitter:image",
          content: `https://${process.env.VUE_APP_HOST}/img/fontos-reifen-preview.jpg`,
        },
      ],
    };
  },
  computed: {
    ...mapState(["pins"]),
  },
})
export default class App extends Vue {
  drawer = false;
  pins!: Record<string, string>;
  savedPinsDialog = false;

  maintenance = false

  mounted(): void {
    this.onLanguageChange(this.$route.params.lang || "de");
  }

  public get availableLocales(): string[] {
    return this.$i18n.availableLocales;
  }

  public get currentLocale(): string {
    return this.$i18n.locale;
  }

  public get menuItems(): Array<{
    icon: string;
    title: string;
    route: { name: string; params?: { lang?: string } };
    hideInNavBar?: boolean;
  }> {
    return [
      {
        icon: "mdi-home",
        title: this.$t("Pages.Home") as string,
        route: { name: "Home", params: { lang: this.$i18n.locale } },
        hideInNavBar: false,
      },
      {
        icon: "mdi-cogs",
        title: this.$t("Pages.Services") as string,
        route: { name: "Services", params: { lang: this.$i18n.locale } },
      },
      {
        icon: "mdi-information",
        title: this.$t("Pages.Gallery") as string,
        route: { name: "Gallery", params: { lang: this.$i18n.locale } },
      },
      {
        icon: "mdi-calendar",
        title: this.$t("Pages.Book") as string,
        route: { name: "Book", params: { lang: this.$i18n.locale } },
        hideInNavBar: true,
      },
      {
        icon: "mdi-shield-account",
        title: this.$t("Pages.Admin") as string,
        route: { name: "Dashboard", params: { lang: this.$i18n.locale } },
        // hideInNavBar: true,
      },
    ];
  }

  language(locale: string): string {
    switch (locale) {
      case "de":
        return "Deutsch"
      case "en":
        return "English"
      case "hu":
        return "Magyar"
      default:
        return locale
    }
  }

  setLanguage(locale: string): void {
    this.$router.replace({
      name: this.$route.name as string,
      params: {
        ...this.$route.params,
        lang: locale,
      }
    })
  }

  @Watch("$route.params.lang")
  onLanguageChange(locale: string): void {
    this.$i18n.locale = locale;
    window.localStorage.setItem("locale", locale);
  }

  @Watch("$vuetify.breakpoint", { deep: true })
  onResize(): void {
    this.drawer = this.drawer && this.$vuetify.breakpoint.smAndDown;
  }
}
