
import { Vue, Component } from "vue-property-decorator";
import CardLink from "@/components/CardLink.vue";

@Component({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  metaInfo() {
    return {
      title: "Fontos Mobiler Reifenservice",
      titleTemplate: "%s",
    };
  },
  components: {
    CardLink,
  },
})
export default class Home extends Vue {
  randomIcon(i: number): string {
    const icons = ["cog", "archive-cog", "cog-refresh", "cogs", "cog-transfer"];
    return "mdi-" + icons[i % icons.length];
  }
  get serviceItems(): unknown[] {
    return [
      { title: this.$t("Home.Services.Mounting") },
      { title: this.$t("Home.Services.Balancing") },
      { title: this.$t("Home.Services.Kuhlfostruch") },
      { title: this.$t("Home.Services.Maintenance") },
      { title: this.$t("Home.Services.Change") },
      { title: this.$t("Home.Services.Disposal") },
      { title: this.$t("Home.Services.Control") },
      { title: this.$t("Home.Services.Repair") },
      { title: this.$t("Home.Services.Storage") },
      { title: this.$t("Home.Services.PressureCheck") },
      { title: this.$t("Home.Services.WaterRefill") },
      { title: this.$t("Home.Services.Exchange") },
      { title: this.$t("Home.Services.Cleaning") },
      { title: this.$t("Home.Services.BrakeControll") },
      { title: this.$t("Home.Services.Sale") },
      { title: this.$t("Home.Services.OilCheck") },
    ];
  }

  get advantageItems(): unknown[] {
    return [
      {
        icon: "mdi-account-multiple",
        title: this.$t("Advantages.Private.Title"),
        items: this.$t("Advantages.Private.Text"),
      },
      {
        icon: "mdi-domain",
        title: this.$t("Advantages.Business.Title"),
        items: this.$t("Advantages.Business.Text"),
      },
      {
        icon: "mdi-heart",
        title: this.$t('Advantages.Other.Title'),
        items: this.$t('Advantages.Other.Text'),
      },
    ];
  }
}
