import axios, { AxiosInstance } from "axios";
import { DateTime } from "luxon";
import { auth } from "./firebase";
import { Booking, SimpleBookingView } from "./types/booking";

class Api {
  http: AxiosInstance;

  constructor() {
    this.http = axios.create();
    if (process.env.NODE_ENV === "development") {
      this.http.defaults.baseURL =
        "http://localhost:5001/fontosreifen/us-central1/api/api";
    } else {
      this.http.defaults.baseURL = "/api";
    }
    this.http.interceptors.request.use((config) => {
      const authUser = auth.getAuth().currentUser
      if (authUser) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config.headers['Authorization'] = `Bearer ${authUser.accessToken}`
      }
      return config;
    }, (error) => {
      return Promise.reject(error);
    });
  }

  async getBookings(): Promise<SimpleBookingView[]> {
    const result = (await this.http.get("/bookings")).data;
    return result;
  }

  async createBooking(b: Booking): Promise<{ id: string, pin: string }> {
    const result = (await this.http.post("/bookings", b)).data;
    console.log(result);
    return result;
  }

  async getOneBooking(id: string, pin: string): Promise<Booking> {
    const result = (await this.http.get(`/bookings/${id}`, { params: { pin } }))
      .data;
    return Booking.fromRaw(result);
  }

  async adminGetBookings(from?: DateTime, to?: DateTime): Promise<Booking[]> {
    const results = (await this.http.get(`/admin/bookings`, { params: { from: from?.toISO(), to: to?.toISO() } }))
      .data;
    return results.map(Booking.fromRaw);
  }

  async adminUpdateBookings(id: string, booking: Partial<Booking>): Promise<boolean> {
    const results = (await this.http.patch(`/admin/bookings/${id}`, booking))
      .data;
    return !!results;
  }

  async createUnavailability(start: DateTime, end: DateTime) {
    const result = (await this.http.post("/admin/unavailable", { start: start.toMillis(), end: end.toMillis() })).data;
    console.log(result);
    return result;
  }
  
  async getUnavailabilities() {
    const result = (await this.http.get("/admin/unavailable")).data;
    return result;
  }
  
  async deleteUnavailability(id: string) {
    await this.http.delete(`/admin/unavailable/${id}`)
  }
}

export default new Api();
