import { DateTime } from "luxon";
import { CarModel } from "../static-data/listOfCars";

export const enum BookingLocation {
  STEYR = 0,
  OUTSIDE_STEYR = 1,
}

export const enum BookingStatus {
  CREATED = "created",
  APPROVED = "approved",
  CANCELLED = "cancelled",
  COMPLETED = "completed",
}

type Car = {
  model?: string | CarModel;
  licensePlate?: string;
  services?: string | string[];
  comment?: string;
};

export class Booking {
  id?: string

  createdAt?: Date
  approvedAt?: Date
  cancelledAt?: Date
  completedAt?: Date

  cancelledMessage?: string

  status: BookingStatus;
  location: BookingLocation;
  event: {
    start: number;
    end: number;
  } | null;

  get start(): DateTime | null {
    return this.event ? DateTime.fromMillis(this.event.start) : null
  }

  get end(): DateTime | null {
    return this.event ? DateTime.fromMillis(this.event.end) : null
  }

  cars: Car[];
  name: string;
  email: string;
  phone: string;
  address: { placeId: string; formattedAddress: string } | null;
  comment?: string
  serviceComment?: string

  constructor() {
    this.status = BookingStatus.CREATED;
    this.event = null;
    this.cars = [{}];
    this.location = BookingLocation.STEYR;
    this.name = "";
    this.email = "";
    this.phone = "";
    this.address = null;
  }

  get length(): number {
    let base = 0;
    switch (this.location) {
      case BookingLocation.STEYR:
        base = 60;
        break;
      case BookingLocation.OUTSIDE_STEYR:
        base = 90;
        break;
      default:
        throw new Error("Unimplemented");
    }
    return base + (this.cars.length - 1) * 45;
  }

  static fromRaw(raw: Booking): Booking {
    const b = new Booking();
    b.id = raw.id
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    b.createdAt = new Date(raw.createdAt);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    b.approvedAt = new Date(raw.approvedAt);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    b.cancelledAt = new Date(raw.cancelledAt);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    b.completedAt = new Date(raw.completedAt);

    b.cancelledMessage = raw.cancelledMessage;

    b.status = raw.status;
    b.event = raw.event;
    b.cars = raw.cars.map((car: Car) =>
      typeof car.model === "object"
        ? Object.assign(car, { model: new CarModel(car.model) })
        : car
    );
    b.location = raw.location;
    b.name = raw.name;
    b.email = raw.email;
    b.phone = raw.phone;
    b.address = raw.address;
    b.comment = raw.comment;
    b.serviceComment = raw.serviceComment;
    return b;
  }
}

export type SimpleBookingView = {
  id: string;
  type: 'client' | 'admin',
  event: {
    start: number;
    end: number;
  };
};
