import i18n from "@/i18n";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/:lang?/services",
    name: "Services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/Services.vue"),
  },
  {
    path: "/:lang?/gallery",
    name: "Gallery",
    component: () =>
      import(/* webpackChunkName: "gallery" */ "../views/Gallery.vue"),
  },
  {
    path: "/:lang?/book",
    name: "Book",
    component: () =>
      import(/* webpackChunkName: "book" */ "../views/Book.vue"),
  },
  {
    path: "/:lang?/booking/:id",
    name: "Booking",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/Booking.vue"),
  },
  {
    path: '/:lang?/admin',
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
    children: [
      {
        path: '',
        redirect: { name: 'Dashboard' }
      },
      {
        path: "dashboard",
        name: 'Dashboard',
        component: () =>
          import(/* webpackChunkName: "admin-dashboard" */ "../views/admin/Dashboard.vue"),
      },
      {
        path: "unavailability",
        name: 'Unavailable',
        component: () =>
          import(/* webpackChunkName: "admin-unavailable" */ "../views/admin/Unavailable.vue"),
      },
    ]
  },
  {
    path: "/:lang?",
    name: "Home",
    component: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const popState = {
  popStateDetected: false
}

popState.popStateDetected = false
window.addEventListener('popstate', () => {
  popState.popStateDetected = true
})

router.beforeEach((to, from, next) => {
  let changeLang = null as string | null

  if (to.path !== '/' && !i18n.availableLocales.includes(to.params.lang))
    changeLang = i18n.locale
  if (popState.popStateDetected)
    changeLang = from.params.lang
  
  popState.popStateDetected = false
  if (changeLang)
    next({ name: to.name || "Home", params: {...to.params, lang: changeLang }})
  else next()
})

export default router;
