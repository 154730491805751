// https://github.com/stonkpunk/my-npm-modules/tree/main/list-of-cars
type RawCarModel = {
  Make: string;
  Model: string;
  Year: number;
};

export class CarModel {
  Make: string;
  Model: string;
  Year: number;

  constructor({ Make, Model, Year }: RawCarModel) {
    this.Make = Make;
    this.Model = Model;
    this.Year = Year;
  }

  get Text(): string {
    return `${this.Make} ${this.Model} ${this.Year}`;
  }
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const listOfCars = require("./list-of-cars.json");

export default listOfCars
  .map((raw: RawCarModel) => new CarModel(raw))
  .sort((a: CarModel, b: CarModel) => {
    if (a.Make > b.Make) return 1;
    if (a.Make < b.Make) return -1;
    if (a.Model > b.Model) return 1;
    if (a.Model < b.Model) return -1;
    return a.Year - b.Year;
  });
