
import { Component, ModelSync, Prop, Vue } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";

@Component({
  computed: {
    ...mapState(["pins"]),
  },
  methods: {
    ...mapMutations(["forgetPin"]),
  },
})
export default class SavedPinsManager extends Vue {
  @ModelSync("value", "change", { type: Boolean }) localValue!: boolean;
  @Prop({ required: false, default: "icon" }) type!: string;
  forgetPin!: (id: string) => void;

  view(id: string): void {
    this.localValue = false;
    if (this.currentId !== id) {
      this.$router.push({ name: "Booking", params: { id, lang: this.$i18n.locale  } });
    }
  }

  get currentId(): string | null {
    return this.$route.params.id || null;
  }
}
